import React from 'react';
import { ListingCard, NamedLink } from '../../../components';
import Slider from 'react-slick';
import '../../../styles/slick/slick.css';
import '../../../styles/slick/slick-theme.css';

import css from './Sections.module.css';
import classNames from 'classnames';

function RecentlyAddedProducts(props) {
  const { listings, intl, viewport, currentUser } = props;

  const sliderSettings = {
    dots: false,
    arrows: true,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: false,
    focusOnSelect: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1128,
        settings: {
          slidesToShow: 3,
          variableWidth: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          variableWidth: false,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 2,
          variableWidth: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          variableWidth: false,
        },
      },
    ],
  };

  const title = intl.formatMessage({ id: 'RecentlyAddedProducts.title' });
  const description = intl.formatMessage({ id: 'RecentlyAddedProducts.description' });
  const exploreMore = intl.formatMessage({ id: 'RecentlyAddedProducts.exploreMore' });

  const isMobileLayout = viewport && viewport.width > 0 && viewport.width < 768;
  return (
    <div className={classNames(css.popularSessions, css.sectionPadding)}>
      <div className={css.contentWidth}>
        <div className={css.sectionHead}>
          <div className={css.leftSec}>
            <h2>{title}</h2>
            <p>{description}</p>
          </div>
          <div className={css.rightSec}>
            <NamedLink name="SearchPage">{exploreMore}</NamedLink>
          </div>
        </div>
        <div className={css.featuredListingsContent}>
          <div className={css.templatesSlider}>
            <Slider {...sliderSettings}>
              {listings?.map((l, index) => (
                <ListingCard
                  isMobileLayout={isMobileLayout}
                  className={css.listingCard}
                  key={index}
                  listing={l}
                  renderSizes={[
                    '(max-width: 549px) 100vw',
                    '(max-width: 767px) 50vw',
                    `(max-width: 1439px) 26vw`,
                    `(max-width: 1920px) 18vw`,
                    `14vw`,
                  ].join(', ')}
                  setActiveListing={() => {}}
                  currentUser={currentUser}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecentlyAddedProducts;
