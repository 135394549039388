import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { Page, LayoutSingleColumn } from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';
import { withViewport } from '../../util/uiHelpers';
import { isArrayLength } from '../../util/genericHelpers';
import { useConfiguration } from '../../context/configurationContext';

import css from './LandingPage.module.css';

//Static assets
import facebookImage from '../../assets/biketribe-facebook-sharing-1200x630.jpg';
import twitterImage from '../../assets/biketribe-twitter-sharing-600x314.jpg';
import SectionHero from './SectionHero/SectionHero';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import RecentlyAddedProducts from './Sections/RecentlyAddedProducts';
import HowItWorks from './Sections/HowItWorks';
import SectionCategories from './Sections/SectionCategories';
import SectionInstagram from './Sections/SectionInstagram';
import FeaturedImages from './Sections/FeaturedImages';

//Section pages

export const LandingPageComponent = props => {
  const {
    sectionHeroSearchKey,
    intl,
    scrollingDisabled,
    history,
    viewport,
    currentUser,
    listings,
  } = props;

  const config = useConfiguration();

  const marketplaceName = config.marketplaceName;

  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { marketplaceName });
  const schemaDescription = intl.formatMessage({
    id: 'LandingPage.schemaDescription',
  });
  const schemaImage = `${config.marketplaceRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        {
          url: `${config.marketplaceRootURL}${twitterImage}`,
          width: 600,
          height: 314,
        },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn
        className={css.pageRoot}
        topbar={<TopbarContainer />}
        footer={<FooterContainer />}
      >
        <div className={css.pageContent}>
          <SectionHero
            viewport={viewport}
            history={history}
            sectionHeroSearchKey={sectionHeroSearchKey}
          />
          <div className={css.aboutUs}>
            <FeaturedImages history={history} />
          </div>
          <SectionCategories viewport={viewport} intl={intl} history={history} />
          {isArrayLength(listings) ? (
            <RecentlyAddedProducts
              currentUser={currentUser}
              listings={listings}
              intl={intl}
              viewport={viewport}
            />
          ) : null}
          <HowItWorks viewport={viewport} intl={intl} />
          <SectionInstagram viewport={viewport} intl={intl} />
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { currentPageResultIds } = state.LandingPage;
  const listings = getListingsById(state, currentPageResultIds);

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    listings,
  };
};

const LandingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    null
  ),
  withViewport,
  injectIntl
)(LandingPageComponent);

export default LandingPage;
