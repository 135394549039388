import React, { forwardRef } from 'react';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { FieldTextInput, Form, IconSearch, Icons } from '../../../../components';
import css from './CustomSearchForm.module.css';

const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;

const CustomSearchForm = forwardRef((props, ref) => {
  return (
    // this is the main coponent that surrounds our complete state
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          handleSubmit,
          isLandingPage,
          isErrorPageSearch,
          placeholder = 'Search',
        } = formRenderProps;

        const classes = classNames(rootClassName, className, {
          [css.errorPageSearch]: isErrorPageSearch,
          [css.landingHeroSearch]: isLandingPage,
        });

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.searchItemsContainer}>
              <div className={css.keywordSearch}>
                {isErrorPageSearch ? <Icons name="searchIcon" /> : null}
                <FieldTextInput
                  id="keywords"
                  name="keywords"
                  className={css.field}
                  type="text"
                  placeholder={placeholder}
                  isUncontrolled
                  inputRef={ref}
                />
              </div>
              <button
                className={classNames(css.button, {
                  [css.mobileButton]: isMobile,
                })}
                type="submit"
              >
                {isErrorPageSearch || isMobile ? 'Search' : <Icons name="searchIcon" />}
              </button>
            </div>
          </Form>
        );
      }}
    />
  );
});

export default CustomSearchForm;
