import React from 'react';
import Slider from 'react-slick';

import '../../../styles/slick/slick.css';
import '../../../styles/slick/slick-theme.css';
import css from './Sections.module.css';

import background1 from '../SectionHero/Assets/background1.png';
import background2 from '../SectionHero/Assets/background2.png';
import background3 from '../SectionHero/Assets/background3.png';
import background4 from '../SectionHero/Assets/background4.png';
import background5 from '../SectionHero/Assets/background5.png';
import background6 from '../SectionHero/Assets/background6.png';

const settings = {
  className: 'center',
  centerMode: true,
  arrows: false,
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 2000,
  speed: 500,
  cssEase: 'ease',
  slidesToShow: 3,
  slidesToScroll: 1,
  centerPadding: '250px',
  speed: 500,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
        centerPadding: '150px',
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        centerPadding: '200px',
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        centerPadding: '100px',
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        centerPadding: '50px',
      },
    },
  ],
};

function FeaturedImages(props) {
  const bannerSlides = [
    { src: background1, alt: 'Slide 1' },
    { src: background2, alt: 'Slide 2' },
    { src: background3, alt: 'Slide 3' },
    { src: background4, alt: 'Slide 4' },
    { src: background5, alt: 'Slide 5' },
    { src: background6, alt: 'Slide 6' },
  ];

  return (
    <div className={css.aboutSlider}>
      <Slider {...settings}>
        {bannerSlides.map(b => {
          return (
            <div className={css.sliderItem} key={b?.alt}>
              <img src={b?.src} alt={b?.alt} />
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default FeaturedImages;
