import React, { useRef } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../../util/reactIntl';
import { createResourceLocatorString } from '../../../util/routes';
import CustomSearchForm from './CustomSearchForm/CustomSearchForm';
import { useConfiguration } from '../../../context/configurationContext';

import css from './SectionHero.module.css';

const SectionHero = props => {
  const { rootClassName, className, intl, history, isMobileLayout } = props;

  const classes = classNames(rootClassName || css.root, className);
  const keywordRef = useRef('');

  const handleSubmit = values => {
    const { keywords } = values;
    const searchPath = keywords ? `/s?keywords=${encodeURIComponent(keywords)}` : '/s';
    history.push(searchPath);
  };

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <div className={css.heroLeftContent}>
          <div className={css.heroInnerContent}>
            <h1 className={css.heroMainTitle}>
              <FormattedMessage id="SectionHero.title" />
            </h1>{' '}
            <div className={css.joinUsbox}>
              <span>Reimagining the way you shop and sell figure skating apparel</span>
            </div>
            <CustomSearchForm
              className={css.filterSearchBox}
              onSubmit={handleSubmit}
              intl={intl}
              ref={keywordRef}
              isMobileLayout={isMobileLayout}
              placeholder="Figure skating, leotards..."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
