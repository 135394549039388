import React from 'react';
import { Icons, NamedLink } from '../../../components';
import css from './Sections.module.css';
import classNames from 'classnames';
import Slider from 'react-slick';
import '../../../styles/slick/slick.css';
import '../../../styles/slick/slick-theme.css';
function HowItWorks(props) {
  const { intl, viewport } = props;

  const isTabLayout = viewport && viewport.width > 0 && viewport.width < 1024;
  const isMobileLayout = viewport && viewport.width > 0 && viewport.width < 768;
  const title = intl.formatMessage({ id: 'HowItWorks.title' });
  const description = intl.formatMessage({ id: 'HowItWorks.description' });

  const howItWorksContent = [
    { title: intl.formatMessage({ id: 'HowItWorks.content1' }), icon: <Icons name="login" /> },
    { title: intl.formatMessage({ id: 'HowItWorks.content2' }), icon: <Icons name="commission" /> },
    { title: intl.formatMessage({ id: 'HowItWorks.content3' }), icon: <Icons name="shipping" /> },
    {
      title: intl.formatMessage({ id: 'HowItWorks.content4' }),
      icon: <Icons name="connections" />,
    },
    {
      title: intl.formatMessage(
        { id: 'HowItWorks.content5' },
        {
          signupLink: (
            <NamedLink name="NewListingPage">
              {intl.formatMessage({ id: 'HowItWorks.contentSignupLink' })}
            </NamedLink>
          ),
        }
      ),
      icon: <Icons name="startSelling" />,
    },
    {
      title: intl.formatMessage({ id: 'HowItWorks.content6' }),
      icon: <Icons name="login" />,
    },
  ];
  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: false,
    focusOnSelect: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1128,
        settings: {
          slidesToShow: 3,
          variableWidth: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          variableWidth: false,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
        },
      },
    ],
  };

  return (
    <div className={classNames(css.howItWorks, css.sectionPadding)}>
      <div className={css.contentWidth}>
        <div className={css.sectionHead}>
          <div className={css.leftSec}>
            <h2>{title}</h2>
            <p>{description}</p>
          </div>
        </div>
        {isTabLayout ? (
          <div className={css.mobileHowItWorksSlider}>
            <Slider {...sliderSettings}>
              {howItWorksContent.map((c, i) => {
                return (
                  <div className={css.block} key={i}>
                    <div className={css.blockIcon}>{c.icon}</div>
                    <p>{c.title}</p>
                  </div>
                );
              })}
            </Slider>
          </div>
        ) : (
          <div className={css.sectionContent}>
            {howItWorksContent.map((c, i) => {
              return (
                <div className={css.block} key={i}>
                  <div className={css.blockIcon}>{c.icon}</div>
                  <p>{c.title}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default HowItWorks;
