import React from 'react';
import classNames from 'classnames';
import Slider from 'react-slick';
import costumes from '../../../assets/costumes.jpg';
import skatesAndBlades from '../../../assets/skatesAndBlades.jpg';
import practicewear from '../../../assets/practicewear.jpg';
import accessories from '../../../assets/accessories.jpg';
import { createResourceLocatorString } from '../../../util/routes';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';

import css from './Sections.module.css';
import '../../../styles/slick/slick.css';
import '../../../styles/slick/slick-theme.css';

function SectionCategories(props) {
  const { intl, viewport, history } = props;
  const routeConfiguration = useRouteConfiguration();

  const isTabLayout = viewport && viewport.width > 0 && viewport.width < 1024;
  const isMobileLayout = viewport && viewport.width > 0 && viewport.width < 768;

  const title = intl.formatMessage({ id: 'SectionCategories.title' });

  const sectionCategoriesContent = [
    {
      title: intl.formatMessage({ id: 'SectionCategories.costumes' }),
      img: costumes,
    },
    {
      title: intl.formatMessage({ id: 'SectionCategories.skatesAndBlades' }),
      img: skatesAndBlades,
    },
    {
      title: intl.formatMessage({ id: 'SectionCategories.practicewear' }),
      img: practicewear,
    },
    {
      title: intl.formatMessage({ id: 'SectionCategories.accessories' }),
      img: accessories,
    },
  ];

  const sliderSettings = {
    dots: false,
    arrows: true,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: false,
    focusOnSelect: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1128,
        settings: {
          slidesToShow: 3,
          variableWidth: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          variableWidth: false,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 2,
          variableWidth: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          variableWidth: false,
        },
      },
    ],
  };

  const routeToSearchPage = title => {
    const transformations = {
      Costumes: 'costumes',
      'Skates and Blades': 'skates-blades',
      Accessories: 'accessories',
      Practicewear: 'practice-wear',
    };

    let formattedTitle =
      transformations[title] ||
      title
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^a-z0-9-]/g, '');

    const encodedFilters = { pub_category: formattedTitle };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, encodedFilters));
  };

  return (
    <div className={classNames(css.categorySection, css.sectionPadding)}>
      <div className={css.contentWidth}>
        <div className={css.sectionHead}>
          <div className={css.leftSec}>
            <h2>{title}</h2>
          </div>
        </div>
        {isTabLayout ? (
          <div className={css.mobileCategorySlider}>
            <Slider {...sliderSettings}>
              {sectionCategoriesContent.map((c, i) => {
                return (
                  <div className={css.block} key={i} onClick={() => routeToSearchPage(c.title)}>
                    <img src={c.img} alt="img" />
                    <h3>{c.title}</h3>
                  </div>
                );
              })}
            </Slider>
          </div>
        ) : (
          <div className={css.sectionContent}>
            {sectionCategoriesContent.map((c, i) => {
              return (
                <div className={css.block} key={i} onClick={() => routeToSearchPage(c.title)}>
                  <img src={c.img} alt="img" />
                  <h3>{c.title}</h3>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default SectionCategories;
