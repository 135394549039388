import React from 'react';
import { ExternalLink } from '../../../components';
import css from './Sections.module.css';

import instagram1 from '../SectionHero/Assets/instagram1.jpg';
import instagram2 from '../SectionHero/Assets/instagram2.jpg';
import instagram3 from '../SectionHero/Assets/instagram3.jpg';
import instagram4 from '../SectionHero/Assets/instagram4.jpg';
import loopImage from '../SectionHero/Assets/loop.png';
import classNames from 'classnames';

function SectionInstagram(props) {
  const { intl } = props;

  const sectionInstagramContent = [
    { image: instagram1, url: 'https://www.instagram.com/p/C5EyyQPLk1z' },
    { image: instagram2, url: 'https://www.instagram.com/p/C4qqgvqR9K1' },
    { image: instagram3, url: 'https://www.instagram.com/reel/C4eU0hYrSxf' },
    { image: instagram4, url: 'https://www.instagram.com/reel/C4Q2ZyERunL' },
  ];

  return (
    <div className={classNames(css.instagramSection, css.sectionPadding)}>
      <div className={css.contentWidth}>
        <div className={css.sectionHead}>
          <div className={css.leftSec}>
            <ExternalLink href="https://www.instagram.com/the.backstagecloset">
              <img src={loopImage} alt="Instagram" />
            </ExternalLink>
          </div>
        </div>

        <div className={css.sectionContent}>
          {sectionInstagramContent.map((c, i) => {
            return (
              <ExternalLink href={c?.url} key={i}>
                <img src={c?.image} alt="Instagram" />
              </ExternalLink>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default SectionInstagram;
